<template>
  <div class="flex flex-col min-h-screen">
    <PortalNavBar
      v-if="!hideHeader"
      class="border-b-2 border-dark"
    />
    <div class="flex-1">
      <slot></slot>
    </div>
    <div class="w-full border-t-2 border-dark">
      <PortalFooter />
    </div>
    <AppTabBar class="lg:hidden" />
  </div>
</template>

<script setup lang="ts">
import PortalNavBar from '@/components/layout/PortalNavBar.vue'
import PortalFooter from '@/components/layout/PortalFooter.vue'
import AppTabBar from '@/components/layout/AppTabBar.vue'
const route = useRoute()

const hideHeader = computed(() => {
  return !!route.meta.hideHeader
})
</script>
